<template>
  <div :class="classList">
    <span class="size14-weight600 label">
      {{ title }}
    </span>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="selectedValue"
          prepend-icon="mdi-calendar"
          data-cy="contact-info-date-of-birth"
          readonly
          v-on="on"
          outlined
          dense
          hide-details
          :rules="[(v) => !!v || $t('required')]"
        />
      </template>
      <v-date-picker
        v-model="selectedValue"
        :max="max"
        :min="min"
        ref="picker"
        :locale="userPreferredLanguage"
        hide-details
      />
    </v-menu>
  </div>
</template>

<script>
import LanguageMixins from '@/core/mixins/LanguageMixins';
export default {
  name: 'PQTextField',
  mixins: [LanguageMixins],
  props: {
    title: String,
    value: String,
    min: String,
    max: String,
    classList: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedValue: this.value || '',
      menu: false,
    };
  },
  watch: {
    selectedValue: {
      immediate: true,
      handler(selectedValue) {
        if (selectedValue) this.menu = false;
        this.$emit('change', selectedValue);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.hint {
  color: var(--v-grey7-base);
}
.label {
  display: block;
  padding-bottom: 3px;
}
</style>
