<template>
  <div :class="classList" id="ProductQuestionsFormSelect">
    <template v-if="title">
      <span class="label">
        {{ title }}
      </span>
    </template>

    <v-select
      v-model="selectedValue"
      :items="list"
      data-cy="contact-info-select"
      outlined
      dense
      hide-details
      label=""
      aria-labelledby="ProductQuestionsFormSelect"
      aria-selected="false"
      tabindex="0"
    >
      <template v-slot:append>
        <slot name="append">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="
                M5.06346 12.1804C5.19244 11.8691 5.49631 11.666 5.83336 11.666H14.1667C14.5037
                11.666 14.8076 11.8691 14.9366 12.1804C15.0656 12.4918 14.9943 12.8503 14.7559
                13.0886L10.5893 17.2553C10.2638 17.5807 9.73621 17.5807 9.41077 17.2553L5.2441
                13.0886C5.00577 12.8503 4.93447 12.4918 5.06346 12.1804Z
              "
              :fill="$vuetify.theme.themes.light.grey7"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="
                M14.9365 7.81955C14.8076 8.13095 14.5037 8.33398 14.1666 8.33398L5.83331 8.33398C5.49626
                8.33398 5.19239 8.13095 5.06341 7.81955C4.93443 7.50816 5.00572 7.14973 5.24405 6.91139L9.41072
                2.74473C9.73616 2.41929 10.2638 2.41929 10.5892 2.74473L14.7559 6.9114C14.9942 7.14973 15.0655
                7.50816 14.9365 7.81955Z
              "
              :fill="$vuetify.theme.themes.light.grey7"
            />
          </svg>
        </slot>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'PQSelect',
  props: {
    title: String,
    value: String,
    list: Array,
    classList: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectedValue: this.value || '',
    };
  },

  watch: {
    selectedValue: {
      immediate: true,
      handler(selectedValue) {
        if (selectedValue) this.$emit('change', selectedValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: block;
  padding-bottom: 3px;
  @include font-size(14, 150, 600);
}
</style>
