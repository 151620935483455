import { render, staticRenderFns } from "./PQTextField.vue?vue&type=template&id=27dc9bff&scoped=true&"
import script from "./PQTextField.vue?vue&type=script&lang=js&"
export * from "./PQTextField.vue?vue&type=script&lang=js&"
import style0 from "./PQTextField.vue?vue&type=style&index=0&id=27dc9bff&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27dc9bff",
  null
  
)

export default component.exports