<template>
  <v-container
    class="pa-0 traveler-info"
    data-cy="product-question-form-component"
  >
    <template v-if="title">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="mb-0 traveler-info-title">
            {{ title }}
          </p>
        </v-col>
      </v-row>
    </template>

    <v-row>
      <v-col
        cols="12"
        v-for="question in questionsCopy"
        :key="question.id"
        class="traveler-info-field"
        :class="questionClass(question)"
      >
        <template v-if="checkFieldType(question.title, 'Title')">
          <PQSelect
            data-cy="product-question-title-component"
            :title="$t('booking.question_form.title')"
            :value="question.value"
            :list="getItems(question.choices)"
            @change="setQuestionValue($event, question)"
          />
        </template>

        <template v-if="checkFieldType(question.title, 'First Name')">
          <PQTextField
            data-cy="product-question-first-name-component"
            :title="$t('booking.question_form.first_name')"
            :value="question.value"
            @change="setQuestionValue($event, question)"
          />
        </template>

        <template v-if="checkFieldType(question.title, 'Last Name')">
          <PQTextField
            data-cy="product-question-last-name-component"
            :title="$t('booking.question_form.last_name')"
            :value="question.value"
            @change="setQuestionValue($event, question)"
          />
        </template>

        <template v-if="checkFieldType(question.title, 'Date of birth')">
          <PQDatePicker
            data-cy="product-question-date-of-birth-component"
            :title="$t('booking.question_form.date_of_birth')"
            min="1920-01-01"
            :max="dateNow"
            :value="question.value"
            @change="setQuestionValue($event, question)"
          />
        </template>

        <template v-if="checkFieldType(question.title, 'Email')">
          <PQTextField
            data-cy="product-question-email-component"
            :title="$t('booking.question_form.email')"
            :hint="$t('booking.question_form.email_hint')"
            :value="question.value"
            @change="setQuestionValue($event, question)"
          />
        </template>

        <template v-if="checkFieldType(question.title, 'Phone Country Code')">
          <div
            data-cy="product-question-phone-country-component"
            id="phoneCodeField"
          >
            <span class="product-phone-title">
              {{ $t('booking.question_form.phone_number') }}
            </span>
            <CountryCodeAutoComplete
              class="d-flex pt-6 country-code"
              :value="question.value"
              @change="setQuestionValue($event, question)"
            />
          </div>
        </template>

        <template v-if="checkFieldType(question.title, 'Phone')">
          <div data-cy="product-question-phone-number-component">
            <PQTextField
              :value="question.value"
              @change="setQuestionValue($event, question)"
            />
          </div>
        </template>
      </v-col>

      <v-col
        cols="12"
        v-for="question in customQuestionsList"
        :key="question.id"
        class="traveler-info-field"
      >
        <template v-if="question.type === 'MultipleChoice'">
          <PQAutoComplete
            :title="question.title"
            :hint="hotelHint(question.title)"
            :value="question.value"
            :list="getItems(question.choices)"
            @change="setQuestionValue($event, question)"
          />
        </template>

        <template v-else>
          <PQTextField
            :title="question.title"
            :value="question.value"
            @change="setQuestionValue($event, question)"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CountryCodeAutoComplete from '@/core/components/CountryCodeAutoComplete';
import PQSelect from './QuestionsSections/PQSelect';
import PQAutoComplete from './QuestionsSections/PQAutoComplete';
import PQTextField from './QuestionsSections/PQTextField';
import PQDatePicker from './QuestionsSections/PQDatePicker';

export default {
  name: 'ProductQuestionsForm',
  components: {
    CountryCodeAutoComplete,
    PQSelect,
    PQAutoComplete,
    PQTextField,
    PQDatePicker,
  },

  props: {
    index: String,
    title: String,
    questions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mainFieldList: [
        'title',
        'date of birth',
        'email',
        'first name',
        'last name',
        'phone country code',
        'phone',
      ],
      questionTitles: {
        'First Name': 'firstName',
        'Last Name': 'lastName',
        Email: 'email',
      },
      answers: {
        firstName: '',
        lastName: '',
        email: '',
      },
      dateNow: new Date().toISOString().substr(0, 10),
      traveler: {
        id: this.index,
        title: null,
        firstName: null,
        lastName: null,
        email: null,
        phoneCode: null,
        phone: null,
      },
    };
  },

  mounted() {
    if (this.customQuestionsList.length) {
      const question = this.customQuestionsList.find((item) =>
        item.title.includes('Special Instructions')
      );
      if (question) {
        this.setQuestionValue('', question);
      }
    }
  },

  computed: {
    questionsCopy() {
      this.getUserProfileInformation();
      return this.mainQuestionsList.map((q) => {
        let questionTitle = this.getQuestionTitleToFieldNameMapping(q.title);
        let suggestedAnswer;
        if (questionTitle) {
          suggestedAnswer = this.answers[questionTitle];
        }
        return { ...q, suggestedAnswer };
      });
    },

    mainQuestionsList() {
      return this.questions.filter((item) =>
        this.mainFieldList.includes(item.title.toLowerCase())
      );
    },

    customQuestionsList() {
      return this.questions.filter(
        (item) => !this.mainFieldList.includes(item.title.toLowerCase())
      );
    },
  },

  methods: {
    getUserProfileInformation() {
      this.answers.firstName = '';
      this.answers.lastName = '';
      this.answers.email = '';
    },

    getQuestionTitleToFieldNameMapping(questionTitle) {
      return this.questionTitles[questionTitle];
    },

    getItems(choices) {
      let list = [];
      if (choices && choices.length) {
        choices.forEach((choice) => {
          list.push({ value: choice.id, text: choice.label });
        });
        return list;
      } else return [];
    },

    questionClass(question) {
      if (
        this.checkFieldType(question.title, 'Title') ||
        this.checkFieldType(question.title, 'date of birth')
      ) {
        return 'traveler-info-field-half';
      } else if (this.checkFieldType(question.title, 'Email')) {
        return 'traveler-info-field-full';
      } else if (this.checkFieldType(question.title, 'Phone Country Code')) {
        return 'traveler-info-field-phone-code';
      } else if (this.checkFieldType(question.title, 'Phone')) {
        return 'traveler-info-field-phone';
      }
    },

    checkFieldType(title, type) {
      return title.toLowerCase() === type.toLowerCase();
    },

    setQuestionValue(value, question) {
      question.value =
        question.title == 'Phone Country Code' ? value.phoneCode : value;
      question.title == 'Flight Number'
        ? (question.value = value.toUpperCase())
        : value;
      if (question.title !== 'Special Instructions (Optional)') {
        this.setTravelers(value, question);
      }
      this.$emit('is-valid', this.checkFormValid());
      this.$emit('formChange', question);
    },

    setTravelers(value, question) {
      if (!question && !value) return;
      switch (question.title) {
        case 'Title':
          this.traveler.title = value;
          break;
        case 'First Name':
          this.traveler.firstName = value;
          break;
        case 'Last Name':
          this.traveler.lastName = value;
          break;
        case 'Email':
          this.traveler.email = value;
          break;
        case 'Phone Country Code':
          this.traveler.phoneCode = value.phoneCode;
          break;
        case 'Phone':
          this.traveler.phone = value;
          break;
      }
      this.$emit('createTraveler', this.traveler);
    },

    checkFormValid() {
      const allQuestions = [...this.customQuestionsList, ...this.questionsCopy];
      const requiredQuestions = allQuestions.filter((item) => item.required);
      const emailQuestions = allQuestions.filter(
        (item) => this.checkFieldType(item.title, 'Email') && item.value
      );
      if (
        !emailQuestions.every((item) =>
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(item.value)
        )
      ) {
        return false;
      }
      return requiredQuestions.length > 0
        ? requiredQuestions.every((item) => item.value)
        : true;
    },

    hotelHint(title) {
      return this.checkFieldType(title, 'Pick a hotel')
        ? this.$t('booking.question_form.hotel_hint')
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.traveler-info {
  margin-bottom: 28px;

  &-title {
    @include font-size(18, 150, 700);
  }

  &-field {
    display: flex;
    align-items: flex-end;
    flex-basis: 50%;
    padding: 12px;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      flex-basis: 100%;
    }

    .v-input__append-inner {
      margin-top: 10px !important;
    }

    & > div {
      width: 100%;
    }

    &-half {
      flex-basis: 100%;
      padding: 0;

      & > div {
        flex-basis: 50%;
        padding: 12px;
      }

      @media (max-width: map-get($grid-breakpoints, 'md')) {
        & > div {
          flex-basis: 100%;
        }
      }
    }

    &-full {
      flex-basis: 100%;
    }

    &-phone {
      flex-grow: 1;

      &-code {
        flex-basis: 13%;

        span {
          position: absolute;
        }
      }

      @media (max-width: map-get($grid-breakpoints, 'md')) {
        flex-basis: 47%;

        &-code {
          flex-basis: 13%;
        }

        & > div {
          flex-basis: 100%;

          &:first-child {
            flex-basis: 100%;
          }
        }
      }

      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        flex-basis: 61%;

        &-code {
          flex-basis: 20%;
        }
      }
    }

    .v-input {
      border-radius: 8px;

      .v-input__slot {
        background: #fff !important;

        fieldset {
          border: 1px solid #e0e0e0;
        }
      }
    }
  }
}

::v-deep .country-code {
  max-width: 116px;
  min-width: 110px;

  .v-input__slot {
    fieldset {
      max-height: 100%;
      top: 0;
    }
  }
}

.product-phone-title {
  @include font-size(14, 150, 600);
}
</style>
