<template>
  <div :class="classList">
    <template v-if="title">
      <span class="label">
        {{ title }}
      </span>
    </template>

    <v-autocomplete
      v-model="selectedValue"
      :items="list"
      data-cy="contact-info-select"
      outlined
      dense
      hide-details
    />

    <template v-if="hint">
      <span class="hint">
        {{ hint }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PQAutoComplete',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    classList: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectedValue: this.value || '',
    };
  },

  watch: {
    selectedValue: {
      immediate: true,
      handler(selectedValue) {
        if (selectedValue) this.$emit('change', selectedValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: block;
  padding-bottom: 3px;
  @include font-size(14, 150, 600);
}

.hint {
  @include font-size(14, 150, 400);
  position: relative;

  @media (min-width: map-get($grid-breakpoints, md)) {
    position: absolute;
  }
}
</style>
