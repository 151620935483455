<template>
  <div :class="classList" id="ProductQuestionsFormText">
    <template v-if="title">
      <span class="label">
        {{ title }}
      </span>
    </template>

    <v-text-field
      data-cy="contact-info-text"
      outlined
      dense
      hide-details
      label=""
      aria-labelledby="ProductQuestionsFormText"
      aria-selected="false"
      tabindex="0"
      :value="selectedValue"
      @input="removeWhiteSpace($event)"
    />

    <template v-if="hint">
      <span class="hint">
        {{ hint }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PQTextField',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    classList: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectedValue: this.value,
    };
  },

  methods: {
    removeWhiteSpace(text) {
      this.selectedValue = text.replace(/^[ ]+|[ ]+$/g, '');
    },
  },

  watch: {
    selectedValue: {
      immediate: true,
      handler(selectedValue) {
        const tmpSelectedValue = selectedValue.replace(/^[ ]+|[ ]+$/g, '');
        this.$emit('change', tmpSelectedValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.hint {
  color: var(--v-grey7-base);
  @include font-size(14, 150, 400);
}
.label {
  display: block;
  padding-bottom: 3px;
  @include font-size(14, 150, 600);
}
</style>
